.groupName {
    font-size: 150%;
    font-weight: bold;
    margin: 0 0 15px 0;
}

.buyerInfo {
    font-size: 120%;
    margin: 0 0 0 0;
    padding: 0 0 15px 0;
}

.buyerLabel {
    font-weight: 500;
}

.orderCode {
    font-size: 150%;
    font-weight: bold;
    color: #1890ff;
    margin: 0 0 15px 0;
}

.backgroundTr {
    background-color: #f5f5f5;
    border-radius: 0;
}

.backgroundTh {
    border-top: 0 !important;
    font-weight: 500 !important;
    padding: 16px 16px !important;
}

.backgroundTd {
    padding: 16px 16px;
    border-bottom: 1px solid #f0f0f0;
}
