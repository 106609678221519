@import url(https://fonts.googleapis.com/css?family=Roboto:300);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}
.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #01579e;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form button:hover,.form button:active,.form button:focus {
  background: #001628;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #001628;
  text-decoration: none;
}
.form .register-form {
  display: none;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before, .container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #EF3B3A;
}
body {
  background: #01579e; /* fallback for old browsers */
  background: linear-gradient(to left, #001628, #001628);
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;      
}
.Table_search__1QLkc {
    margin: 0 0 20px 20px !important;
    width: auto;
    min-width: 80px;
}

.Table_backgroundTr__3vDs2 {
    background-color: #f5f5f5;
    border-radius: 0;
}

.Table_backgroundTh__3LaIy {
    border-top: 0 !important;
    font-weight: 500 !important;
    padding: 16px 16px !important;
}

.Table_backgroundTd__1fgBe {
    padding: 16px 16px;
    border-bottom: 1px solid #f0f0f0;
    vertical-align: middle !important;
}

.Table_pagination__3GnEl {
    float: right;
}
.OverviewTable_paymentMethod__2eg0D {
    margin: 0 0 20px 20px !important;
    width: auto;
    min-width: 80px;
}

.OverviewTable_search__1vr5o {
    /* margin: 0 0 20px 20px !important; */
    width: auto;
    min-width: 80px;
}

.OverviewTable_backgroundTr__2CKZ2 {
    background-color: #f5f5f5;
    border-radius: 0;
}

.OverviewTable_backgroundTh__H7hwe {
    border-top: 0 !important;
    border-right: 1px solid #f0f0f0;
    font-weight: 500 !important;
    padding: 16px 16px 0 0 !important;
}

.OverviewTable_backgroundTd__3zxki {
    padding: 16px 16px;
    border-bottom: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    vertical-align: middle !important;
    padding-top: 20px; 
    padding-bottom: 20px;
}

.OverviewTable_pagination__WAl3B {
    float: right;
}

.OverviewTable_selectEventBrowser__1xjMs {
    width: 300px;
    margin-bottom: 5px;
    margin-right: 10px;
    padding-right: 10px;
}

.OverviewTable_blockOverviewBrowser__2u1US {
    height: 130px;
    float: left;
    margin-bottom: 10px; 
    background-image: -webkit-linear-gradient(90deg,#38ef7d 0%, #11998e 100%);
    font-family: Monaco;
    border-radius: 10px; 
    width: 250px;
}

.OverviewTable_blockSearchBrowser__2H0Yx {
    height: 130px;
    float: left;
    margin-bottom: 10px; 
    background-image: white;
    font-family: Monaco;
    border-radius: 10px; 
    width: 280px;
}
.OverviewTable_spanTable__3GgjI {
    float: right;
    font-weight: bold;
}
.SummaryCard_IconWrapper__N_7YU {
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 16px;
  right: 12px;
}

.SummaryCard_Icon__1JN0y {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.GroupInfoTable_filter-group__3kQBp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.GroupInfoTable_input-search__1wcAI {
  width: 200px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 0 10px;
  margin-right: 10px;
}

.GroupInfoTable_select-status__3n4AY {
  width: 150px;
  border-radius: 4px;
  margin-right: 10px;
}

.GroupInfoTable_btn-search__287P0 {
  width: 100px;
  height: 32px;
  background-color: #002876;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.Attendants_inputType__n6DXP {
    float: left;
    width: 50%;
}

.Attendants_paymentMethod__3QTUP {
    margin: 0 0 20px 20px !important;
    width: auto;
    min-width: 80px;
}

.Attendants_search__2IyFD {
    margin: 0 0 20px 20px !important;
    width: auto;
    min-width: 80px;
}

.Attendants_backgroundTr__3Qw7Q {
    background-color: #f5f5f5;
    border-radius: 0;
}

.Attendants_backgroundTh__2UPBP {
    border-top: 0 !important;
    border-right: 1px solid #f0f0f0;
    font-weight: 500 !important;
    padding: 16px 16px 16px 0 !important;
}

.Attendants_backgroundTd__1dKiy {
    padding: 16px 16px;
    border-bottom: 0.5px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    vertical-align: middle !important;
    padding-top: 20px; 
    padding-bottom: 20px; 
}

.Attendants_pagination__1uhoh {
    float: right;
    margin-top: 10px;
}

.Attendants_buttonSearch__1xyPw {
    float: right;
}
.OrderDetail_groupName__rpDDk {
    font-size: 150%;
    font-weight: bold;
    margin: 0 0 15px 0;
}

.OrderDetail_buyerInfo__1z9he {
    font-size: 120%;
    margin: 0 0 0 0;
    padding: 0 0 15px 0;
}

.OrderDetail_buyerLabel__X7Dag {
    font-weight: 500;
}

.OrderDetail_orderCode__1M9Dl {
    font-size: 150%;
    font-weight: bold;
    color: #1890ff;
    margin: 0 0 15px 0;
}

.OrderDetail_backgroundTr__2_ibh {
    background-color: #f5f5f5;
    border-radius: 0;
}

.OrderDetail_backgroundTh__1qoil {
    border-top: 0 !important;
    font-weight: 500 !important;
    padding: 16px 16px !important;
}

.OrderDetail_backgroundTd__-Rz90 {
    padding: 16px 16px;
    border-bottom: 1px solid #f0f0f0;
}

.CouponCodes_inputType__2wC66 {
    float: left;
    width: 50%;
}

.CouponCodes_paymentMethod__3SGmD {
    margin: 0 0 20px 20px !important;
    width: auto;
    min-width: 80px;
}

.CouponCodes_search__1RPsL {
    margin: 0 0 20px 20px !important;
    width: auto;
    min-width: 80px;
}

.CouponCodes_backgroundTr__25Sk6 {
    background-color: #f5f5f5;
    border-radius: 0;
}

.CouponCodes_backgroundTh__vE2mQ {
    border-top: 0 !important;
    border-right: 1px solid #f0f0f0;
    font-weight: 500 !important;
    padding: 16px !important;
}

.CouponCodes_backgroundTd__2L7sj {
    padding: 16px 16px;
    border-bottom: 0.5px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    vertical-align: middle !important;
    padding-top: 20px; 
    padding-bottom: 20px; 
}

.CouponCodes_pagination__1DZeq {
    float: right;
    margin-top: 10px;
}

.CouponCodes_buttonSearch__3SHrW {
    float: right;
}
