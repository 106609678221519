.IconWrapper {
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 16px;
  right: 12px;
}

.Icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}