.inputType {
    float: left;
    width: 50%;
}

.paymentMethod {
    margin: 0 0 20px 20px !important;
    width: auto;
    min-width: 80px;
}

.search {
    margin: 0 0 20px 20px !important;
    width: auto;
    min-width: 80px;
}

.backgroundTr {
    background-color: #f5f5f5;
    border-radius: 0;
}

.backgroundTh {
    border-top: 0 !important;
    border-right: 1px solid #f0f0f0;
    font-weight: 500 !important;
    padding: 16px 16px 16px 0 !important;
}

.backgroundTd {
    padding: 16px 16px;
    border-bottom: 0.5px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    vertical-align: middle !important;
    padding-top: 20px; 
    padding-bottom: 20px; 
}

.pagination {
    float: right;
    margin-top: 10px;
}

.buttonSearch {
    float: right;
}