.paymentMethod {
    margin: 0 0 20px 20px !important;
    width: auto;
    min-width: 80px;
}

.search {
    /* margin: 0 0 20px 20px !important; */
    width: auto;
    min-width: 80px;
}

.backgroundTr {
    background-color: #f5f5f5;
    border-radius: 0;
}

.backgroundTh {
    border-top: 0 !important;
    border-right: 1px solid #f0f0f0;
    font-weight: 500 !important;
    padding: 16px 16px 0 0 !important;
}

.backgroundTd {
    padding: 16px 16px;
    border-bottom: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    vertical-align: middle !important;
    padding-top: 20px; 
    padding-bottom: 20px;
}

.pagination {
    float: right;
}

.selectEventBrowser {
    width: 300px;
    margin-bottom: 5px;
    margin-right: 10px;
    padding-right: 10px;
}

.blockOverviewBrowser {
    height: 130px;
    float: left;
    margin-bottom: 10px; 
    background-image: -webkit-linear-gradient(90deg,#38ef7d 0%, #11998e 100%);
    font-family: Monaco;
    border-radius: 10px; 
    width: 250px;
}

.blockSearchBrowser {
    height: 130px;
    float: left;
    margin-bottom: 10px; 
    background-image: white;
    font-family: Monaco;
    border-radius: 10px; 
    width: 280px;
}
.spanTable {
    float: right;
    font-weight: bold;
}