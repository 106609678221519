.filter-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input-search {
  width: 200px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 0 10px;
  margin-right: 10px;
}

.select-status {
  width: 150px;
  border-radius: 4px;
  margin-right: 10px;
}

.btn-search {
  width: 100px;
  height: 32px;
  background-color: #002876;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}